<template>
  <div class="main-div">
    <div class="row top-row">
      <div class="col-xl-4 offset-xl-2 col-lg-6 col-md-6 col-sm-6 col-12">
        <img
          class="img img-bottom-small-screens"
          src="../assets/apperance-1-corrected.png"
        />
      </div>
      <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
        <img class="img" src="../assets/Noc-View2corrected.jpg" />
      </div>
    </div>
    <div class="row middle-row">
      <div class="col-xl-4 offset-xl-2 col-lg-6 col-md-6 col-sm-6 col-12">
        <img
          class="img img-bottom-small-screens"
          src="../assets/Noc-View3corrected.jpg"
        />
      </div>
      <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
        <img class="img" src="../assets/View4corrected.jpg" />
      </div>
    </div>


        <div class="row middle-row">
      <div class="col-xl-4 offset-xl-2 col-lg-6 col-md-6 col-sm-6 col-12">
        <img
          class="img img-bottom-small-screens"
          src="../assets/View2corrected.jpg"
        />
      </div>
      <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
        <img class="img" src="../assets/View3corrected.jpg" />
      </div>
    </div>

        <div class="row middle-row">
      <div class="col-xl-4 offset-xl-2 col-lg-6 col-md-6 col-sm-6 col-12">
        <img
          class="img img-bottom-small-screens"
          src="../assets/Noc-View1corrected.jpg"
        />
      </div>
      <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
        <img class="img" src="../assets/Noc-View4corrected.jpg" />
      </div>
    </div>


    <div class="row bottom-row">
      <div class="col-xl-4 offset-xl-2 col-lg-6 col-md-6 col-sm-6 col-12">
        <img
          class="img img-bottom-small-screens"
          src="../assets/materials-1.png"
        />
      </div>
      <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
        <img class="img" src="../assets/Noc-View5corrected.jpg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.img {
  height: 100%;
  width: 100%;
  box-shadow: 0 10px 30px 0 rgba(0,0,0,0.2);
}
.row {
  margin: 0;
  padding-bottom: 24px;
  padding-top: 24px;
  margin-bottom: 24px;
  margin-top: 24px;
}
.top-row {
  margin-top: 0px !important;
  padding-top: 50px;
  background: url("../assets/gray-background.png");
  background-repeat: no-repeat;
  background-size: 56.5% 100%;
  background-position: right;
}
.bottom-row {
  padding-bottom: 36px !important;
  background: url("../assets/gray-background.png");
  background-repeat: no-repeat;
  background-size: 56.5% 100%;
  background-position: right;
}
.middle-row {
  padding-top: 30px;
  padding-bottom: 30px;
  background: url("../assets/gray-background.png");
  background-repeat: no-repeat;
  background-size: 56.5% 100%;
  background-position: left;
}
@media (max-width: 576px) {
  .img-bottom-small-screens {
    padding-bottom: 24px;
  }
  .top-row {
    padding-top: 20px;
  }
}
</style>