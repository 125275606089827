<template>
  <div class="main-div">
    <div class="row headline-row">
      <div class="headline col-xl-10 offset-xl-2 col-lg-10 offset-lg-2">
        -3, -2, -1 i Prizemlje
      </div>
    </div>
    <div class="row img-row even-row">
      <div
        class="image-col col-xl-2 offset-xl-2 col-lg-3 col-md-6 col-sm-6 col-12"
      >
        <div class="image-placeholder">
          <div class="price">
            <span v-if="this.prices[0].firstPart != ''"
              >{{ this.prices[0].firstPart }}
            </span>
            <span>{{ this.prices[0].price }}</span
            ><span> + PDV</span>
          </div>
          <img
            @click="openModal(0)"
            class="img"
            src="../assets/minus3-30-3-2023mala.png"
          />
        </div>
      </div>
      <div class="image-col col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12">
        <div class="image-placeholder">
          <div class="price">
            <span v-if="this.prices[1].firstPart != ''"
              >{{ this.prices[1].firstPart }}
            </span>
            <span>{{ this.prices[1].price }}</span
            ><span> + PDV</span>
          </div>
          <img
            @click="openModal(1)"
            class="img"
            src="../assets/minus2mala-1-24-2023.png"
          />
        </div>
      </div>
      <div class="image-col col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12">
        <div class="image-placeholder">
          <div class="price">
            <span v-if="this.prices[2].firstPart != ''"
              >{{ this.prices[2].firstPart }}
            </span>
            <span>{{ this.prices[2].price }}</span
            ><span> + PDV</span>
          </div>
          <img @click="openModal(2)" class="img" src="../assets/minus1mala-1-24-2023.png" />
        </div>
      </div>
      <div class="image-col col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12">
        <div class="image-placeholder">
          <div class="price">
            <span v-if="this.prices[3].firstPart != ''"
              >{{ this.prices[3].firstPart }}
            </span>
            <span>{{ this.prices[3].price }}</span
            ><span> + PDV</span>
          </div>
          <img
            @click="openModal(3)"
            class="img"
            src="../assets/OsnovaPrizemlja30-3-2023-mala.png"
          />
        </div>
      </div>
    </div>
    <div class="row headline headline-row">
      <div class="headline col-xl-10 offset-xl-2 col-lg-10 offset-lg-2">
        1, 2, 3 i 4 sprat
      </div>
    </div>
    <div class="row img-row odd-row">
      <div
        class="image-col col-xl-2 offset-xl-2 col-lg-3 col-md-6 col-sm-6 col-12"
      >
        <div class="image-placeholder">
          <div class="price poslovni-apartmani">
            <span v-if="this.prices[4].firstPart != ''"
              >{{ this.prices[4].firstPart }}
            </span>
            <span>{{ this.prices[4].price }}</span
            >
            <!-- <span> + PDV</span> -->
          </div>
          <img
            @click="openModal(4)"
            class="img"
            src="../assets/prvispratmala1-24-2023.png"
          />
        </div>
      </div>
      <div class="image-col col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12">
        <div class="image-placeholder">
          <div class="price"  v-if="this.prices[5].show == true">
            <span v-if="this.prices[5].firstPart != ''"
              >{{ this.prices[5].firstPart }}
            </span>
            <span>{{ this.prices[5].price }}</span
            ><span> + PDV</span>
          </div>
          <img
            @click="openModal(5)"
            class="img"
            src="../assets/OsnovaDrugogSprata1600x1600.png"
          />
        </div>
      </div>
      <div class="image-col col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12">
        <div class="image-placeholder">
          <div class="price" v-if="this.prices[6].show == true">
            <span v-if="this.prices[6].firstPart != ''"
              >{{ this.prices[6].firstPart }}
            </span>
            <span>{{ this.prices[6].price }}</span
            ><span> + PDV</span>
          </div>
          <img
            @click="openModal(6)"
            class="img"
            src="../assets/Osnova-treceg-sprata-22-7-1600x1600.png"
          />
        </div>
      </div>
      <div class="image-col col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12">
        <div class="image-placeholder">
          <div class="price" v-if="this.prices[7].show == true">
            <span v-if="this.prices[7].firstPart != ''"
              >{{ this.prices[7].firstPart }}
            </span>
            <span>{{ this.prices[7].price }}</span
            ><span> + PDV</span>
          </div>
          <img
            @click="openModal(7)"
            class="img"
            src="../assets/Osnova-cetvrtog-sprata-22-7-1600x1600.png"
          />
        </div>
      </div>
    </div>
    <div class="row headline headline-row">
      <div class="headline col-xl-10 offset-xl-2 col-lg-10 offset-lg-2">
        5, 6, Povučeni Sprat i Penthouse
      </div>
    </div>
    <div class="row img-row even-row">
      <div
        class="image-col col-xl-2 offset-xl-2 col-lg-3 col-md-6 col-sm-6 col-12"
      >
        <div class="image-placeholder">
          <div class="price" v-if="this.prices[8].show == true">
            <span v-if="this.prices[8].firstPart != ''"
              >{{ this.prices[8].firstPart }}
            </span>
            <span>{{ this.prices[8].price }}</span
            ><span> + PDV</span>
          </div>
          <img
            @click="openModal(8)"
            class="img"
            src="../assets/OsnovaPetogSprata1600x1600.png"
          />
        </div>
      </div>
      <div class="image-col col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12">
        <div class="image-placeholder">
          <div class="price" v-if="this.prices[9].show == true">
            <span v-if="this.prices[9].firstPart != ''"
              >{{ this.prices[9].firstPart }}
            </span>
            <span>{{ this.prices[9].price }}</span
            ><span> + PDV</span>
          </div>
          <img
            @click="openModal(9)"
            class="img"
            src="../assets/OsnovaSestogSprata1600x1600.png"
          />
        </div>
      </div>
      <div class="image-col col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12">
        <div class="image-placeholder">
          <div class="price">
            <span v-if="this.prices[10].firstPart != ''"
              >{{ this.prices[10].firstPart }}
            </span>
            <span>{{ this.prices[10].price }}</span
            ><span> + PDV</span>
          </div>
          <img
            @click="openModal(10)"
            class="img"
            src="../assets/povuceni-sprat-mala-fixed.png"
          />
        </div>
      </div>
      <div class="image-col col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12">
        <div class="image-placeholder">
          <div class="price">
            <span v-if="this.prices[10].firstPart != ''"
              >{{ this.prices[10].firstPart }}
            </span>
            <span>{{ this.prices[10].price }}</span
            ><span> + PDV</span>
          </div>
          <img
            @click="openModal(10)"
            class="img"
            src="../assets/penthouse-mala-fixed.png"
          />
        </div>
      </div>
    </div>
    <div>
      <SliderModal />
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import SliderModal from "../components/SliderModal.vue";
export default {
  data() {
    return {
      showModal: false,
      prices: [
        {
          show: true,
          firstPart: "Parking mesto",
          price: "25.000 €",
        },
        {
          show: true,
          firstPart: "Parking mesto",
          price: "25.000 €",
        },
        {
          show: true,
          firstPart: "Parking mesto",
          price: "25.000 €",
        },
        {
          show: true,
          firstPart: "Lokali",
          price: "3.500 € / m2",
        },
        {
          show: true,
          firstPart: "Poslovni apartmani",
          price: "2.690 + 20% pdv-a",
        },
        {
          show: false,
          firstPart: "",
          price: "2.490 € / m2",
        },
        {
          show: false,
          firstPart: "",
          price: "2.490 € / m2",
        },
        {
          show: false,
          firstPart: "",
          price: "2.490 € / m2",
        },
        {
          show: false,
          firstPart: "",
          price: "2.490 € / m2",
        },
        {
          show: false,
          firstPart: "",
          price: "2.490 € / m2",
        },
        {
          show: true,
          firstPart: "",
          price: "2.690 € / m2",
        },
        {
          show: true,
          firstPart: "",
          price: "2.690 € / m2",
        },
      ],
    };
  },
  methods: {
    openModal(parameter) {
      this.$store.commit("setCurrentIndex", parameter);
      $("#sliderModal").modal("show");
    },
  },
  computed: {
    currentIndex: {
      // getter
      get: function () {
        return this.$store.currentIndex;
      },
      // setter
      set: function (parameter) {
        this.$store.commit("setCurrentIndex", parameter);
      },
    },
  },
  components: {
    SliderModal,
  },
};
</script>

<style scoped>
@supports (-moz-appearance: none) {
  .headline {
    font-family: "Nunito", sans-serif !important;
    font-weight: 500 !important;
  }
}
.poslovni-apartmani {
  font-size: 16px !important;
}
.headline {
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  letter-spacing: 0;
  line-height: 56px;
  font-weight: 600;
}
.row {
  margin: 0%;
  padding-top: 36px;
  padding-bottom: 36px;
}
.price {
  display: none;
  width: 100%;
  position: absolute;
  bottom: 16px;
  right: 0;
  height: 24px;
  background-color: #f2f2f2;
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}
.even-row {
  background: url("../assets/gray-background.png");
  background-repeat: no-repeat;
  background-size: 56.5% 100%;
  background-position: right;
}
.odd-row {
  background: url("../assets/gray-background.png");
  background-repeat: no-repeat;
  background-size: 56.5% 100%;
  background-position: left;
}
.image-placeholder {
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
}
.img {
  width: 100%;
  cursor: pointer;
}
.img-row {
  padding-top: 72px;
  padding-bottom: 72px;
}
.headline {
  padding-left: 36px;
}
@media (max-width: 991px) {
  .image-placeholder {
    margin-bottom: 24px;
  }
  .even-row {
    background-size: 100% 100%;
  }
  .odd-row {
    background-size: 100% 100%;
  }
  .headline {
    text-align: center;
  }
  .image-col {
    padding-left: 0%;
    padding-right: 0%;
  }
  .headline {
    padding-left: 0px;
  }
}
@media (max-width: 576px) {
  .headline-row {
    display: none;
  }
  .img {
    pointer-events: none;
  }
  .price {
    display: inline;
  }
  .even-row {
    padding-bottom: 6px;
    padding-top: 6px;
  }
  .odd-row {
    padding-bottom: 6px;
    padding-top: 6px;
  }
}
</style>