<template>
  <div>
    <div class="row even-row">
      <div class="navigation">
        <ul class="vertical-list-project">
          <li class="vertical-list-project-item" @click="goToImg('minus3')">
            -3
          </li>
          <li class="vertical-list-project-item" @click="goToImg('minus2')">
            -2
          </li>
          <li class="vertical-list-project-item" @click="goToImg('minus1')">
            -1
          </li>
          <li class="vertical-list-project-item" @click="goToImg('prizemlje')">
            P
          </li>
          <li class="vertical-list-project-item" @click="goToImg('prvi-sprat')">
            1
          </li>
          <li
            class="vertical-list-project-item"
            @click="goToImg('drugi-sprat')"
          >
            2
          </li>
          <li
            class="vertical-list-project-item"
            @click="goToImg('drugi-sprat2')"
          >
            3
          </li>
          <li
            class="vertical-list-project-item"
            @click="goToImg('drugi-sprat')"
          >
            4
          </li>
          <li
            class="vertical-list-project-item"
            @click="goToImg('drugi-sprat2')"
          >
            5
          </li>
          <li class="vertical-list-project-item" @click="goToImg('drugi-sprat')">
            6
          </li>
          <li class="vertical-list-project-item" @click="goToImg('poslednji')">
            P
          </li>
        </ul>
      </div>
      <div class="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12 offset-xl-1">
        <div class="image-placeholder">
          <img class="img" src="../assets/minus3-high-res.png" id="minus3" />
        </div>
      </div>
    </div>
    <div class="row odd-row">
      <div class="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12 offset-xl-1">
        <div class="image-placeholder">
          <img class="img" src="../assets/minus2-high-res.png" id="minus2" />
        </div>
      </div>
    </div>
    <div class="row even-row">
      <div class="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12 offset-xl-1">
        <div class="image-placeholder">
          <img class="img" src="../assets/minus1-high-res.png" id="minus1" />
        </div>
      </div>
    </div>
    <div class="row odd-row">
      <div class="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12 offset-xl-1">
        <div class="image-placeholder">
          <img class="img" src="../assets/prizemlje-high-res.png" id="prizemlje" />
        </div>
      </div>
    </div>
    <div class="row even-row">
      <div class="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12 offset-xl-1">
        <div class="image-placeholder">
          <img class="img" src="../assets/prvi-sprat-high-res.png" id="prvi-sprat" />
        </div>
      </div>
    </div>
    <div class="row odd-row">
      <div class="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12 offset-xl-1">
        <div class="image-placeholder">
          <img class="img" src="../assets/2-4-6-high-res.png" id="drugi-sprat" />
        </div>
      </div>
    </div>
    <div class="row even-row">
      <div class="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12 offset-xl-1">
        <div class="image-placeholder">
          <img class="img" src="../assets/3-5-high-res.png" id="drugi-sprat2" />
        </div>
      </div>
    </div>
    <div class="row odd-row">
      <div class="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12 offset-xl-1">
        <div class="image-placeholder">
          <img class="img" src="../assets/povucena-etaza-high-res.png" id="poslednji" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    goToImg(parameter) {
      document.getElementById(parameter).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
@supports (-moz-appearance: none) {
  .vertical-list-project-item {
    font-family: "Nunito", sans-serif !important;
    font-weight: 500 !important;
  }
}
.navigation {
  position: fixed;
  top: 150px;
  z-index: 10;
}
.vertical-list-project {
  list-style: none;
  width: 64px;
  background-color: #ffffff;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
  padding: 0px;
  margin-top: 50px;
}
.vertical-list-project-item {
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 56px;
  text-align: center;
  cursor: pointer;
}
.img {
  width: 100%;
  height: 100%;
  margin-top: 5%;
}
.row {
  margin: 0%;
  padding-bottom: 64px;
}
.even-row {
  background: white;
}
.odd-row {
  background-color: rgba(0, 0, 0, 0.05);
}
.image-placeholder {
  width: 100%;
}
@media (max-width: 1199px) {
  .navigation {
    top: 0;
    left: 0%;
    position: absolute;
  }
  .vertical-list-project {
    margin-top: 0;
    width: 100%;
    box-shadow: none;
    border-bottom: 1px solid black;
  }
  .vertical-list-project-item {
    display: inline;
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media (max-width: 824px) {
  .vertical-list-project-item {
    margin-left: 15px;
    margin-right: 15px;
  }
}
@media (max-width: 750px) {
  .vertical-list-project-item {
    margin-left: 12px;
    margin-right: 12px;
  }
}
@media (max-width: 640px) {
  .vertical-list-project-item {
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media (max-width: 600px) {
  .vertical-list-project-item {
    font-size: 24px;
  }
}
@media (max-width: 520px) {
  .vertical-list-project-item {
    font-size: 20px;
    line-height: 52px;
  }
}
@media (max-width: 490px) {
  .vertical-list-project-item {
    font-size: 18px;
    line-height: 50px;
  }
}
@media (max-width: 460px) {
  .vertical-list-project-item {
    margin-left: 8px;
    margin-right: 8px;
  }
}
@media (max-width: 420px) {
  .vertical-list-project-item {
    margin-left: 7px;
    margin-right: 7px;
  }
}
@media (max-width: 400px) {
  .vertical-list-project-item {
    margin-left: 5px;
    margin-right: 5px;
    font-size: 16px;
  }
}
</style>