<template>
  <div class="row">
    <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 img-column">
      <img class="logo" src="../assets/contact.png" />
    </div>
    <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 text-column">
      <p class="headline">Kontakt</p>
      <p class="text">
        +381 69 4963969 <br />
        +381 64 1844208
        <br />
        <br />
        zinvestingtim@gmail.com
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@supports (-moz-appearance: none) {
  .text {
    font-family: "Nunito", sans-serif !important;
    font-weight: 500 !important;
  }
  .headline {
    font-family: "Nunito", sans-serif !important;
    font-weight: 500 !important;
  }
}
.row {
  margin: 0%;
    background: url("../assets/gray-background.png");
  background-repeat: no-repeat;
  background-size: 56.5% 100%;
  background-position: right;
}
.logo{
  box-shadow: 0 10px 30px 0 rgba(0,0,0,0.2);
}
.img-column {
  padding-left: 200px;
  padding-top: 150px;
}
.text-column {
  padding-left: 180px;
  padding-top: 180px;
  color: #6d7278;
  /* background: rgba(0, 0, 0, 0.05); */
}
.text {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 32px;
  font-weight: 600;
  width: 400px;
}
.headline {
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  letter-spacing: 0;
  line-height: 56px;
  font-weight: 700;
}
@media (max-width: 1730px) {
  .img-column {
    padding-left: 100px;
  }
  .text-column {
    padding-left: 220px;
  }
}
@media (max-width: 1464px) {
  .img-column {
    padding-left: 50px;
  }
  .text-column {
    padding-left: 260px;
  }
}
@media (max-width: 1310px) {
  .img-column {
    padding-left: 30px;
  }
  .text-column {
    padding-left: 300px;
  }
}
@media (max-width: 1200px) {
  .img-column {
    text-align: center;
    padding-left: 0px !important;
    padding-bottom: 2%;
  }
  .text-column {
    width: 10%;
    padding-left: 40%;
    padding-top: 5%;
    padding-bottom: 2%;
  }
}
@media (max-width: 826px) {
  .logo {
    width: 100%;
  }
  .img-column {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0% !important;
    padding-top: 0% !important;
  }
  .text {
    width: 100%;
  }
}
@media (max-width: 570px) {
  .text-column {
    width: 10%;
    padding-left: 25%;
    padding-top: 5%;
    padding-bottom: 2%;
  }
}
@media (max-width: 540px) {
}
@media (max-width: 540px) {
}
</style>