<template>
  <div>
    <div class="gmap_canvas">
      <iframe
        width="100%"
        height="100%"
        id="gmap_canvas"
        src="https://maps.google.com/maps?q=%2B44%C2%B0+47'+39.6%22,+20%C2%B0+29'+35.4&t=k&z=17&ie=UTF8&iwloc=&output=embed"
        frameborder="0"
        scrolling="no"
        marginheight="0"
        marginwidth="0"
      ></iframe
      >
    </div>
  </div>

</template>

<script>
export default {};
</script>

<style scoped>
.gmap_canvas {
  height: 100vh;
  width: 100%;
}
.gmap_iframe {
  height: 100vh;
  width: 100%;
}
</style>>
