<template>
  <div>
    <img :src="getImgUrl(backgrounds[current])" class="testImg"/>
    
    <!-- :style="{'background-image': 'url(' + require('../assets/' + image ) + ')','transition': 'all 3s ease'}" -->
    <div class="home" id="home">
      <img class="logo" src="../assets/vojislava-ilica-33-logo-white.svg" />
      <router-link :to="{ name: 'MoreInfo' }">
        <button class="home-button">VIŠE INFORMACIJA</button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      image: "View1corrected.jpg",
      preloadedImages: [],
      backgrounds: [
        "View1corrected.jpg",
        "View2corrected.jpg",
        "View3corrected.jpg",
        "View4corrected.jpg",
        "View5corrected.jpg",
        "Noc-View1corrected.jpg",
        "Noc-View2corrected.jpg",
        "Noc-View3corrected.jpg",
        "Noc-View4corrected.jpg",
        "Noc-View5corrected.jpg",
      ],
      current: 0,
    };
  },
  mounted() {
    setInterval(() => {
      this.nextBackground();
    }, 30000);
  },
  methods: {
    nextBackground() {
      this.current++;
      this.current = Math.abs(this.current) % this.backgrounds.length;
      this.image = this.backgrounds[this.current];
    },
    getImgUrl(image) {
      return require("../assets/" + image);
    },
  },
  components: {},
  created() {
    this.backgrounds.forEach((item) => {
      var img = new Image();
      img.src = item;
      this.preloadedImages.push(img);
    });
  },
};
</script>
<style scoped>
.testImg {
  pointer-events: none;
  position: absolute;
  display: block;
  width: 100%;
  height: 100vh;
  object-fit: cover; /* or object-fit: contain; */
  z-index: -1;
    -webkit-transition: all 3s ease;
  transition: all 3s ease;
}
/* .fade-enter-active {
  transition: opacity 3s ease-in-out;
}

.fade-enter-to {
  opacity: 1;
}

.fade-enter {
  opacity: 0;
} */
.home {
  /* background-image: url("../assets/View1corrected.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  /* -webkit-transition: all 3s ease;
  transition: all 3s ease; */
}
.logo {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  top: 400px;
}
.home-button {
  height: 80px;
  width: 424px;
  border-radius: 40px;
  background-color: #fa6400;
  box-shadow: 0 10px 30px 0 #fa6400;
  border: none;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  top: 800px;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  letter-spacing: 6px;
  line-height: 24px;
  text-align: center;
  font-weight: 600;
}
@media (max-width: 1880px) {
  .home-button {
    top: 650px;
  }
}
@media (max-width: 530px) {
  .home-button {
    width: 70%;
  }
  .logo {
    width: 70%;
  }
}
@media (max-width: 450px) {
  .home-button {
    letter-spacing: 2px;
  }
}
</style>
