<template>
  <div class="row">
    <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 img-column">
      <img class="logo" src="../assets/materials-1.png" />
    </div>
    <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 text-column">
      <p class="headline">Materijali</p>
      <p class="text">
        Kako su kvalitetan objekat i zadovoljan kupac naši ciljevi u izgradnji
        stambenih objekata, materijali koje koristimo moraju biti prvoklasni.
        <br />
        <br />
        Objekat će biti izgrađen termo blokom 20 cm, a spoljna izolacija će biti
        kamena vuna debljine 10 cm, tako da će uz vrhunsku aluminijumsku
        stolariju sa troslojnim staklima toplotni gubici biti minimalni. Kako je
        za komforan život neophodna zvučna izolacija, posebnu pažnju ćemo
        obratiti na zvučnu izolaciju između stanova. U delu objekta će biti
        formirana strukturalna fasada, sa staklenim terasama. Planirana je
        ugradnja toplotnih pumpi vazduh-voda, preko kojih će se stanovi grejati,
        podnim grejanjem, a takođe će se i topla voda za kupatila i kuhinje
        zgrevati preko toplotnih pumpi. Takav način grejanja omogućuje minimalne
        troškove uz maksimalan komfor. Svaki stan će biti opremljen klima
        uređajem. Unutrašnja vrata će biti furnirani hrast, ispunjen šupljom
        ivericom. Parketi je troslojni rustik hrast dimenzija do 2,2 m dužine,
        renomiranog proizvođača Lamar.
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@supports (-moz-appearance: none) {
  .text {
    font-family: "Nunito", sans-serif !important;
    font-weight: 500 !important;
  }
  .headline {
    font-family: "Nunito", sans-serif !important;
    font-weight: 500 !important;
  }
}
.logo {
  z-index: 10;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
}
.row {
  margin: 0%;
}
.img-column {
  padding-left: 250px;
  padding-top: 150px;
}
.text-column {
  padding-left: 80px;
  padding-top: 140px;
  color: #6d7278;
  background: rgba(0, 0, 0, 0.05);
}
.text {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 32px;
  font-weight: 600;
  width: 400px;
}
.headline {
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  letter-spacing: 0;
  line-height: 56px;
  font-weight: 700;
}
@media (max-width: 1730px) {
  .img-column {
    padding-left: 100px;
  }
  .text-column {
    padding-left: 120px;
  }
}
@media (max-width: 1464px) {
  .img-column {
    padding-left: 50px;
  }
}
@media (max-width: 1200px) {
  .img-column {
    text-align: center;
    padding-left: 0px !important;
    padding-bottom: 2%;
    padding-top: 2%;
  }
  .text-column {
    padding-top: 50px;
  }
  .text {
    width: 100%;
  }
}
@media (max-width: 610px) {
  .logo {
    width: 100%;
  }
  .img-column {
    text-align: center;

    padding: 0%;
  }
  .text-column {
    padding-left: 24px;
  }
}
</style>