<template>
  <div>
    <div class="row main-row main-row-right">
      <div
        class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 big-picture-col"
      >
        <div class="image-div">
          <p class="headline">Dvosoban stan od 48 m2</p>
          <div class="image-placeholder">
            <img
              :src="getImgUrl(this.bigPicture)"
              v-bind:alt="this.bigPicture"
              class="big-picture"
            />
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row small-pictures-row-top">
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-4">
            <img
              @click="setImage('S1-Dnevni-boravak-2.jpg', 0)"
              class="small-picture small-picture-right"
              src="../assets/stan1-dnevni-boravak-2.png"
            />
          </div>
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-4">
            <img
              class="small-picture small-picture-right"
              @click="setImage('S1-Dnevni-boravak-3.jpg', 1)"
              src="../assets/stan1-dnevni-boravak-3.png"
            />
          </div>
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-4">
            <img
              @click="setImage('S1-Dnevni-boravak-4.jpg', 2)"
              class="small-picture small-picture-right"
              src="../assets/stan1-dnevni-boravak-4.png"
            />
          </div>
        </div>
        <div class="row small-pictures-row">
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-6">
            <img
              @click="setImage('S1-Kuhinja.jpg', 3)"
              class="small-picture small-picture-right"
              src="../assets/stan1-kuhinja.png"
            />
          </div>
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-6">
            <img
              @click="setImage('S1-Soba.jpg', 4)"
              class="small-picture small-picture-right"
              src="../assets/stan1-soba.png"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- second part -->

    <div class="row main-row main-row-left">
      <div
        class="
          col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12
          left-small-pictures-column
        "
      >
        <p class="headline big-screens-headline">Trosoban stan na uglu 60 m2</p>
        <div class="row small-pictures-row-top-left">
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
            <img
              @click="setImage2('S2-Dnevni-boravak-1.jpg', 0)"
              class="small-picture"
              src="../assets/stan2-dnevni-boravak-1.png"
            />
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
            <img
              class="small-picture"
              @click="setImage2('S2-Dnevni-boravak-2.jpg', 1)"
              src="../assets/stan2-dnevni-boravak-2.png"
            />
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
            <img
              @click="setImage2('S2-Kuhinja-1.jpg', 2)"
              class="small-picture"
              src="../assets/stan2-kuhinja.png"
            />
          </div>
        </div>
        <div class="row small-pictures-row">
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
            <img
              @click="setImage2('S2-Kuhinja-2.jpg', 3)"
              class="small-picture"
              src="../assets/stan2-kuhinja2.png"
            />
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
            <img
              @click="setImage2('S2-Soba1.jpg', 4)"
              class="small-picture"
              src="../assets/stan2-soba1.png"
            />
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
            <img
              @click="setImage2('S2-Soba2.jpg', 5)"
              class="small-picture"
              src="../assets/stan2-soba2.png"
            />
          </div>
        </div>
      </div>
      <div
        class="
          col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12
          big-picture-col-right
        "
      >
        <p class="headline small-screens-headline">
          Trosoban stan na uglu 60 m2
        </p>
        <div class="image-div">
          <div class="image-placeholder">
            <img
              :src="getImgUrl(this.bigPicture2)"
              v-bind:alt="this.bigPicture2"
              class="big-picture"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- third part -->

    <div class="row main-row main-row-right">
      <div
        class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 big-picture-col"
      >
        <div class="image-div">
          <p class="headline">Trosoban stan na uglu 57 m2</p>
          <div class="image-placeholder">
            <img
              :src="getImgUrl(this.bigPicture3)"
              v-bind:alt="this.bigPicture3"
              class="big-picture"
            />
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row small-pictures-row-top">
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-4">
            <img
              @click="setImage3('S3-Dnevni-boravak-1.jpg', 0)"
              class="small-picture small-picture-right"
              src="../assets/stan3-dnevni-boravak1.png"
            />
          </div>
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-4">
            <img
              class="small-picture small-picture-right"
              @click="setImage3('S3-Dnevni-boravak-2.jpg', 1)"
              src="../assets/stan3-dnevni-boravak2.png"
            />
          </div>
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-4">
            <img
              @click="setImage3('S3-Kuhinja.jpg', 2)"
              class="small-picture small-picture-right"
              src="../assets/stan3-kuhinja.png"
            />
          </div>
        </div>
        <div class="row small-pictures-row">
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-6">
            <img
              @click="setImage3('S3-Soba1.jpg', 3)"
              class="small-picture small-picture-right"
              src="../assets/stan3-soba.png"
            />
          </div>
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-6">
            <img
              @click="setImage3('S3-Soba2.jpg', 4)"
              class="small-picture small-picture-right"
              src="../assets/stan3-soba2.png"
            />
          </div>
        </div>
      </div>
    </div>
    <ApartmentSlider />
  </div>
</template>

<script>
import $ from "jquery";
import ApartmentSlider from "../components/ApartmentSlider.vue";
export default {
  data() {
    return {
      bigPicture: "S1-Dnevni-boravak-1.jpg",
      bigPicture2: "S2-Dnevni-boravak-1.jpg",
      bigPicture3: "S3-Dnevni-boravak-1.jpg",
    };
  },
  methods: {
    getImgUrl(image) {
      return require("../assets/" + image);
    },
    setImage(parameter, index) {
      this.$store.commit("setBigModalPicture", parameter);
      this.$store.commit("setAppartmentImages", 1);
      this.$store.commit("setCurrentIndexAppartment", index);
      this.bigPicture = parameter;
      if (window.innerWidth > 576) {
        $("#apartmentSliderModal").modal("show");
      }
    },
    setImage2(parameter, index) {
      this.$store.commit("setBigModalPicture", parameter);
      this.$store.commit("setAppartmentImages", 2);
      this.$store.commit("setCurrentIndexAppartment", index);
      this.bigPicture2 = parameter;
      if (window.innerWidth > 576) {
        $("#apartmentSliderModal").modal("show");
      }
    },
    setImage3(parameter, index) {
      this.$store.commit("setBigModalPicture", parameter);
      this.$store.commit("setAppartmentImages", 3);
      this.$store.commit("setCurrentIndexAppartment", index);
      this.bigPicture3 = parameter;
      if (window.innerWidth > 576) {
        $("#apartmentSliderModal").modal("show");
      }
    },
  },
  components: {
    ApartmentSlider,
  },
};
</script>

<style scoped>
@supports (-moz-appearance: none) {
  .headline {
    font-family: "Nunito", sans-serif !important;
    font-weight: 500 !important;
  }
  .headline-left {
    font-family: "Nunito", sans-serif !important;
    font-weight: 500 !important;
  }
}
.small-screens-headline {
  display: none;
}
.small-picture {
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  cursor: pointer;
}
.big-picture {
  height: 100%;
  width: 100%;
}
.big-picture-right {
  height: 45%;
  width: 65%;
}
.main-row {
  padding-bottom: 48px;
  margin-bottom: 36px !important;
}
.main-row-right {
  background: url("../assets/gray-background.png");
  background-repeat: no-repeat;
  background-size: 56.5% 100%;
  background-position: right;
}
.main-row-left {
  background: url("../assets/gray-background.png");
  background-repeat: no-repeat;
  background-size: 56.5% 100%;
  background-position: left;
}
.small-pictures-row {
  padding-top: 24px;
}
.small-pictures-row-top {
  padding-top: 280px;
}
.small-pictures-row-top-left {
  padding-top: 100px;
}
.left-small-pictures-column {
  padding-left: 200px;
  padding-top: 100px;
}
.row {
  margin: 0%;
}
.headline {
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  letter-spacing: 0;
  line-height: 56px;
  font-weight: 600;
}
.headline-left {
  margin-bottom: 120px;
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  letter-spacing: 0;
  line-height: 56px;
  font-weight: 600;
}
.big-picture-col {
  padding-top: 150px;
  padding-left: 300px;
}
.big-picture-col-left {
  padding-top: 150px;
  padding-left: 70px;
}
.big-picture-col-right {
  padding-top: 150px;
  padding-left: 70px;
}
.image-placeholder {
  height: 580px;
  width: 580px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
}
@media (max-width: 1850px) {
  .big-picture-col {
    padding-top: 80px;
    padding-left: 150px;
  }
}
@media (max-width: 1572px) {
  .left-small-pictures-column {
    padding-left: 100px;
  }
  .big-picture-col-right {
    padding-left: 40px;
    padding-top: 130px;
  }
}
@media (max-width: 1520px) {
  .big-picture-col {
    padding-top: 20px;
    padding-left: 80px;
  }
}
@media (max-width: 1372px) {
  .left-small-pictures-column {
    padding-left: 40px;
  }
}
@media (max-width: 1360px) {
  .small-picture-right {
    width: 110%;
  }
  .big-picture-col {
    padding-top: 20px;
    padding-left: 30px;
  }
}
@media (max-width: 1286px) {
  .left-small-pictures-column {
    padding-left: 30px;
  }
  .big-picture-col-right {
    padding-left: 20px;
    padding-top: 130px;
  }
}
@media (max-width: 1256px) {
  .small-picture-right {
    width: 120%;
    height: 110%;
  }
  .big-picture-col {
    padding-top: 20px;
    padding-left: 10px;
  }
}
@media (max-width: 1230px) {
  .left-small-pictures-column {
    padding-left: 10px;
  }
}
@media (max-width: 1199px) {
  /* .small-screens-headline{
    display:block
  }
   */
  .big-screens-headline {
    text-align: center;
  }
  .small-picture-right {
    width: 100%;
    height: 100%;
  }
  .big-picture-col {
    padding-right: 20%;
    padding-left: 20%;
    text-align: center;
    padding-bottom: 5%;
  }
  .image-placeholder {
    width: 100%;
  }
  .small-pictures-row-top {
    padding-top: 40px;
  }
  .small-pictures-row-top-left {
    padding-top: 40px;
  }
  .big-picture-col-right {
    padding-right: 20%;
    padding-left: 20%;
    text-align: center;
    padding-bottom: 5%;
    padding-top: 40px;
  }
}
@media (max-width: 948px) {
  .big-picture-col {
    padding-right: 15%;
    padding-left: 15%;
    text-align: center;
    padding-bottom: 5%;
  }
  .big-picture-col-right {
    padding-right: 15%;
    padding-left: 15%;
    text-align: center;
    padding-bottom: 5%;
  }
}
@media (max-width: 824px) {
  .big-picture-col {
    padding-right: 10%;
    padding-left: 10%;
    text-align: center;
    padding-bottom: 5%;
  }
  .big-picture-col-right {
    padding-right: 10%;
    padding-left: 10%;
    text-align: center;
    padding-bottom: 5%;
  }
  .image-placeholder {
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 576px) {
  .big-picture-col {
    padding-right: 0%;
    padding-left: 0%;
    text-align: center;
    padding-bottom: 0%;
  }
  .big-picture-col-right {
    padding-right: 0%;
    padding-left: 0%;
    text-align: center;
    padding-bottom: 0%;
  }
  .image-placeholder {
    height: 100%;
    width: 100%;
  }
  .third-small-picture {
    margin-top: 12px;
  }
  .left-small-pictures-column {
    padding-top: 50px;
  }
}
</style>