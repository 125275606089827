<template>
  <div
    class="modal fade"
    id="sliderModal"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-xl customModal"
      role="document"
    >
      <div class="modal-content customModalContent">
        <div class="modal-body customModalBody">
          <div v-if="$route.name == 'MoreInfo'">
            <div class="img-placeholder" v-for="i in [currentIndex]" :key="i">
              <a class="prev" @click="next" href="#">&#10094;</a>
              <transition-group name="fade" tag="div">
                <div v-for="i in [currentIndex]" :key="i">
                  <div v-if=" prices[Math.abs(currentIndex) % images.length] != undefined && !excludedPrices.includes(currentIndex)" class="price-div">
                    <span
                      class="price-text"
                      v-if="prices[Math.abs(currentIndex) % images.length].firstPart != ''"
                    >
                      {{
                        prices[Math.abs(currentIndex) % images.length].firstPart
                      }}
                    </span>
                    <br class="breakline-price" />
                    <span class="price">{{
                      prices[Math.abs(currentIndex) % images.length].price
                    }}</span
                    ><br class="breakline-price" />
                    <span v-if="currentIndex != 4" class="price-text"> + PDV</span>
                  </div>
                  <img class="img-modal" :src="currentImg"/>
                </div>
              </transition-group>
              <a class="next" @click="prev" href="#">&#10095;</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      excludedPrices: [5,6,7,8,9],
      preloadedPictures: [],
      images: [
        "minus3-velika30-3-2023.png",
        "minus2-1-24-2023.png",
        "minus1-1-24-2023.png",
        "OsnovaPrizemlja30-3-2023velika.png",
        "prvisprat1-24-2023.png",
        "OsnovaDrugogSprata.jpg",
        "Osnova-treceg-sprata-22-7-velika.png",
        "Osnova-cetvrtog-sprata-22-7-velika.png",
        "OsnovaPetogSprata.png",
        "OsnovaSestogSprata.png",
        "Vila-Povuceni-Sprat-resized.jpg",
        "Penthouse-resized.jpg",
      ],
      prices: [
        {
          firstPart: "Parking mesto",
          price: "25.000 €",
        },
        {
          firstPart: "Parking mesto",
          price: "25.000 €",
        },
        {
          firstPart: "Parking mesto",
          price: "25.000 €",
        },
        {
          firstPart: "Lokali",
          price: "3.500 € / m2",
        },
        {
          firstPart: "Poslovni apartmani",
          price: "2.690 + 20% pdv-a",
        },
        {
          firstPart: "",
          price: "2.490 € / m2",
        },
        {
          firstPart: "",
          price: "2.490 € / m2",
        },
        {
          firstPart: "",
          price: "2.490 € / m2",
        },
        {
          firstPart: "",
          price: "2.490 € / m2",
        },
        {
          firstPart: "",
          price: "2.490 € / m2",
        },
        {
          firstPart: "",
          price: "2.690 € / m2",
        },
        {
          firstPart: "",
          price: "2.690 € / m2",
        },
      ],
      timer: null,
    };
  },
  methods: {
    next: function () {
      this.$store.commit("setCurrentIndex", this.currentIndex - 1);
      console.log(this.currentIndex);
    },
    prev: function () {
      this.$store.commit("setCurrentIndex", this.currentIndex + 1);
      console.log(this.currentIndex);
    },
    getImgUrl(image) {
      return require("../assets/" + image);
    },
  },

  computed: {
    ...mapState({
      currentIndex: (state) => state.currentIndex,
    }),
    currentImg: function () {
      return require("../assets/" +
        this.images[Math.abs(this.currentIndex) % this.images.length]);
    },
  },
};
</script>

<style scoped>
@supports (-moz-appearance: none) {
  .price {
    font-family: "Nunito", sans-serif !important;
    font-weight: 300 !important;
    letter-spacing: 0px !important;
  }
  .price-text {
    font-family: "Nunito", sans-serif !important;
    font-weight: 500 !important;
    letter-spacing: 0px !important;
  }
}
.price {
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 40px;
  letter-spacing: 0;
  line-height: 56px;
}
.price-text {
  color: #6d7278;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  font-weight: 600;
}
.price-div {
  position: absolute;
  right: -15%;
  bottom: 0;
  height: auto;
  padding-left: 12px;
  padding-bottom: 6px;
  padding-top: 6px;
  width: 25%;
  background-color: #f2f2f2;
}
.img-apartment-zoom {
  width: 100%;
  height: 100%;
}
.img-modal {
  height: 100%;
  width: 130%;
  margin-left: -15%;
   background-image: url("../assets/Poslovni-Prostor-I-Sprat-final.png"), url("../assets/Garaza-II-final.png"), url("../assets/Garaza-I-final.png"), url("../assets/Poslovni-Prostor-Prizemlje-final.png");
}

.customModalContent {
  z-index: 10;
  background: transparent;
  border: none;
  background-image: url("../assets/II-Sprat-fixed.jpg"), url("../assets/III-Sprat-fixed.jpg"), url("../assets/IV-Sprat-fixed.jpg"), url("../assets/V-Sprat-fixed.jpg"), url("../assets/VI-Sprat-fixed.jpg"), url("../assets/Vila-Povuceni-Sprat-resized.jpg"), url("../assets/Penthouse-resized.jpg");
}
.customModalBody {
  padding: 0% !important;
  border: none;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.9s ease;
  overflow: hidden;
  visibility: visible;
  position: absolute;
  width: 100%;
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  visibility: hidden;
  width: 100%;
  opacity: 0;
}
.prev,
.next {
  position: absolute;
  cursor: pointer;
  top: 40%;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 28px;
  padding-right: 28px;
  background-color: #fa6400;
  box-shadow: 0 10px 30px 0 #fa6400;
  font-weight: bold;
  font-size: 18px;
  transition: 0.7s ease;
  text-decoration: none;
  user-select: none;
  color: #ffffff;
  border-radius: 50%;
  height: 70px;
}
.next {
  right: -100px;
}
.prev {
  left: -100px;
}
.main-div {
  height: 100%;
}
.row {
  margin: 0%;
}
@media (max-width: 1500px) {
.img-modal {
  height: 100%;
  width: 120%;
  margin-left: -10%;
}
.price-div{
  right: -10%;
}
}
@media (max-width: 1400px) {
.img-modal {
  height: 100%;
  width: 100%;
  margin-left: 0%;
}
.price-div{
  right: 0%;
}
.next {
  right: -20px;
}
.prev {
  left: -20px;
}
}
@media (max-width: 1199px) {
  .img-modal {
  height: 100%;
  width: 130%;
  margin-left: -15%;
}
  .breakline-price {
    display: none;
  }
  .price {
    font-size: 22px;
    letter-spacing: 0;
    line-height: 24px;
  }
  .price-text {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 600;
  }
  .price-div {
    text-align: center;
    position: absolute;
    right: 0;
    bottom: 0;
    height: auto;
    padding-left: 12px;
    width: 90%;
    margin-right: 5%;
    padding-bottom: 2px;
    padding-top: 2px;
  }
}
@media (max-width: 1100px) {
  .img-modal {
  height: 100%;
  width: 120%;
  margin-left: -10%;
}
}
@media (max-width: 1000px) {
  .img-modal {
  height: 100%;
  width: 110%;
  margin-left: -5%;
}
}
@media (max-width: 991px) {
  .img-modal {
    width: 170%;
    margin-left: -35%;
  }
  .price-div {
    width: 100%;
  }
  .next {
  right: -100px;
}
.prev {
  left: -100px;
}
}
@media (max-width: 880px) {
  .img-modal {
    /* height: 700px; */
    width: 150%;
    margin-left: -25%;
  }
  .price-div {
    width: 100%;
  }
}
@media (max-width: 788px) {
  .img-modal {
    width: 140%;
    margin-left: -20%;
  }
  .price-div {
    width: 100%;
  }
}
@media (max-width: 746px) {
  .img-modal {
    width: 140%;
    margin-left: -20%;
  }
    .price-div {
    width: 100%;
  }
  .prev,
  .next {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 18px;
    padding-right: 18px;
    height: 50px;
  }
  .next {
    right: -40px;
  }
  .prev {
    left: -40px;
  }
}
@media (max-width: 710px) {
  .img-modal {
    /* height: 700px; */
    width: 130%;
    margin-left: -15%;
  }
    .price-div {
    width: 100%;
    /* bottom: 21.5%; */
  }
}
@media (max-width: 660px) {
  .img-modal {

    width: 120%;
    margin-left: -10%;
  }
    .price-div {
    width: 100%;
   
  }
}
@media (max-width: 620px) {
  .img-modal {
   
    width: 110%;
    margin-left: -5%;
  }
    .price-div {
    width: 100%;

  }
  .prev,
  .next {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 18px;
    padding-right: 18px;
    height: 50px;
  }
  .next {
    right: 20px;
  }
  .prev {
    left: 40px;
  }
  .price-div {
    width: 95%;
    /* margin-left: 20%; */
  }
}
@media (max-width: 520px) {
  .img-apartment-zoom {
    width: 95%;
    height: 100%;
  }
  .modal-content {
    border: none;
  }
  .img-modal {
    height: 400px;
    width: 95%;
  }
  .prev,
  .next {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 18px;
    padding-right: 18px;
    height: 50px;
  }
  .next {
    right: 40px;
  }
  .prev {
    left: 20px;
  }
}
</style>