<template>
  <div
    class="modal fade"
    id="apartmentSliderModal"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-xl customModal"
      role="document"
    >
      <div class="modal-content customModalContent">
        <div class="modal-body customModalBody">
          <div v-if="$route.name == 'Apartments'">
            <a class="prev" @click="nextAppartment()">&#10094;</a>
            <img
              class="img-apartment-zoom"
              :src="currentAppartmentImg"
            />
            <a class="next" @click="prevAppartment()">&#10095;</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {

      timer: null,
    };
  },
  methods: {
    nextAppartment: function () {
      this.$store.commit("setCurrentIndexAppartment", this.currentIndexAppartment - 1);
    },
    prevAppartment: function () {
      this.$store.commit("setCurrentIndexAppartment", this.currentIndexAppartment + 1);
    },
  },

  computed: {
    ...mapState({
      currentIndexAppartment: (state) => state.currentIndexAppartment,
      bigModalPicture: (state) => state.bigModalPicture,
      appartmentImages: (state) => state.appartmentImages,
    }),
    currentAppartmentImg: function () {
      return require("../assets/" +
        this.appartmentImages[Math.abs(this.currentIndexAppartment) % this.appartmentImages.length]);
    },
  },
};
</script>

<style scoped>
@supports (-moz-appearance: none) {
  .price {
    font-family: "Nunito", sans-serif !important;
    font-weight: 300 !important;
    letter-spacing: 0px !important;
  }
  .price-text {
    font-family: "Nunito", sans-serif !important;
    font-weight: 500 !important;
    letter-spacing: 0px !important;
  }
}
.price {
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 40px;
  letter-spacing: 0;
  line-height: 56px;
}
.price-text {
  color: #6d7278;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  font-weight: 600;
}
.price-div {
  position: absolute;
  right: -15%;
  bottom: 0;
  height: auto;
  padding-left: 12px;
  padding-bottom: 6px;
  padding-top: 6px;
  width: 25%;
  background-color: #f2f2f2;
}
.img-apartment-zoom {
  width: 100%;
  height: 100%;
}
.img-modal {
  height: 100%;
  width: 130%;
  margin-left: -15%;
}

.customModalContent {
  z-index: 10;
  background: transparent;
  border: none;
}
.customModalBody {
  padding: 0% !important;
  border: none;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.9s ease;
  overflow: hidden;
  visibility: visible;
  position: absolute;
  width: 100%;
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  visibility: hidden;
  width: 100%;
  opacity: 0;
}
.prev,
.next {
  position: absolute;
  cursor: pointer;
  top: 40%;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 28px;
  padding-right: 28px;
  background-color: #fa6400;
  box-shadow: 0 10px 30px 0 #fa6400;
  font-weight: bold;
  font-size: 18px;
  transition: 0.7s ease;
  text-decoration: none;
  user-select: none;
  color: #ffffff;
  border-radius: 50%;
  height: 70px;
}
.next {
  right: -100px;
}
.prev {
  left: -100px;
}
.main-div {
  height: 100%;
}
.row {
  margin: 0%;
}
@media (max-width: 1400px) {
.next {
  right: -20px;
}
.prev {
  left: -20px;
}
.img-apartment-zoom {
  margin-left: 10%;
  width: 80%;
  height: 100%;
}
}
@media (max-width: 1199px) {
.img-apartment-zoom {
  margin-left: 0%;
  width: 100%;
  height: 100%;
}
.next {
  right: -100px;
}
.prev {
  left: -100px;
}
}
@media (max-width: 1040px) {
.next {
  right: -80px;
}
.prev {
  left: -80px;
}
}
@media (max-width: 991px) {
  .img-apartment-zoom {
  margin-left: -10%;
  width: 120%;
  height: 100%;
}
  .next {
  right: -140px;
}
.prev {
  left: -140px;
}
}
@media (max-width: 794px) {
    .img-apartment-zoom {
  margin-left: -5%;
  width: 110%;
  height: 100%;
}
  .prev,
  .next {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 18px;
    padding-right: 18px;
    height: 50px;
  }
  .next {
    right: -80px;
  }
  .prev {
    left: -80px;
  }
}
@media (max-width: 678px) {
      .img-apartment-zoom {
  margin-left: 0%;
  width: 100%;
  height: 100%;
}
  .prev,
  .next {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 18px;
    padding-right: 18px;
    height: 50px;
  }
  .next {
    right: 20px;
  }
  .prev {
    left: 40px;
  }
}
</style>