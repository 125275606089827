<template>
  <div>
    <div class="row main-row">
      <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 img-column">
        <div class="row top-img-row">
          <div
            class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 single-img-col"
          >
            <img class="logo" src="../assets/investitor-edited-1.png" />
            <div class="image-headline">Zlatiborska vila I</div>
            <div class="image-headline">Zavrseno 2016</div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
            <img class="logo" src="../assets/investitor-added.png" />
            <div class="image-headline">Zlatiborska Vila II</div>
            <div class="image-headline">Zavrseno 2017</div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
            <img class="logo" src="../assets/investitor-2.png" />
            <div class="image-headline">Zlatiborska vila III</div>
            <div class="image-headline">Zavrseno 2019</div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
            <img class="logo" src="../assets/investitor-3.png" />
            <div class="image-headline">Zlatiborska vila IV</div>
            <div class="image-headline">Zavrseno 2019</div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
            <img class="logo" src="../assets/investitor-4.png" />
            <div class="image-headline">Rosensteingasse 10</div>
            <div class="image-headline">Zavrseno 2021</div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 text-column">
        <p class="headline">Investitor</p>
        <p class="text">
          Firma Z INVESTING TIM iz Novog Sada je osnovana 2015-te godine od
          strane ekipe ljudi koja se već više od 15 godina bavi invesitranjem i
          izgradnjom objekata.
          <br />
          <br />
          Ono što smo kao firma Z INVESTING TIM do sada uradili su 4 objekta na
          Zlatiboru i objekat u Beču. Ono na šta smo posebno ponosni, osim
          kvaliteta i dostupnosti kupcima u toku, pa i po isteku garancije je to
          da su svi objekti završeni u predviđenom roku, bez i jednog dana
          kašnjenja.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@supports (-moz-appearance: none) {
  .text {
    font-family: "Nunito", sans-serif !important;
    font-weight: 500 !important;
  }
  .headline {
    font-family: "Nunito", sans-serif !important;
    font-weight: 500 !important;
  }
  .image-headline{
        font-family: "Nunito", sans-serif !important;
    font-weight: 500 !important;
  }
}
.image-headline{
  padding-top: 4px;
  padding-bottom: 6px;
background-color: #F2F2F2;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}
.top-img-row {
  padding-bottom: 28px;
}
.main-row {
  min-height: 850px;
  background: url("../assets/gray-background.png");
  background-repeat: no-repeat;
  background-size: 56.5% 100%;
  background-position: right;
}
.img-column {
  padding-left: 50px;
  padding-top: 120px;
}
.text-column {
  padding-left: 80px;
  padding-top: 180px;
  color: #6d7278;
  /* background: rgba(0, 0, 0, 0.05); */
}
.text {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 32px;
  font-weight: 600;
  width: 400px;
}
.headline {
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  letter-spacing: 0;
  line-height: 56px;
  font-weight: 700;
}
.row {
  margin: 0%;
}
.logo {
  width: 99.9%;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
}
@media (max-width: 1400px) {
  .img-column {
    padding-left: 20px;
    padding-top: 150px;
  }
}
@media (max-width: 1200px) {
  .img-column {
    padding-left: 0px;
    padding-top: 100px;
    padding-right: 0%;
  }
  .text-column {
    padding-left: 80px;
    padding-top: 40px;
    padding-bottom: 20px;
    color: #6d7278;
    background: rgba(0, 0, 0, 0.05);
  }
  .text {
    width: 100%;
  }
}
@media (max-width: 700px) {
  .img-column {
    padding-top: 20px;
  }
}
@media (max-width: 576px) {
  .img-column {
    padding-top: 20px;
  }
  .logo {
    width: 100%;
    /* margin-bottom: 24px; */
  }
  .top-img-row {
    padding-bottom: 0%;
  }
  .text-column {
    padding-left: 24px;
  }
}
</style>